import { captureException } from '@sentry/vue'

export default defineNuxtRouteMiddleware(async () => {
  const iStore = identityStore()
  const { state } = storeToRefs(identityStore())

  if (state.value !== 'loggingOut') {
    try {
      await iStore.getSession()
    }
    catch (error) {
      captureException({
        message: 'Error getting session',
        error,
      })
    }
  }
})
