export default defineNuxtPlugin({
  setup() {
    const {
      public: { apiBaseUrl },
    } = useRuntimeConfig()

    if (!apiBaseUrl)
      throw new Error(`Please ensure NUXT_PUBLIC_API_BASE_URL is defined in the env variables ${apiBaseUrl}`)

    const api = $fetch.create({
      baseURL: apiBaseUrl,
      onRequest({ options }) {
        const identityCookie = useIdentityCookie()

        if (identityCookie.value) {
          options.headers = options.headers || {}
          // @ts-expect-error Property 'Authorization' does not exist on type 'HeadersInit'r
          options.headers.Authorization = `Bearer ${identityCookie.value?.token ?? ''}`
        }
      },
    })

    return {
      provide: {
        api,
      },
    }
  },
})
