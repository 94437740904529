<script setup lang="ts">
import type { ButtonProps } from '~~/types/base/Button'
import { NuxtLink } from '#components'
import ui from '~/ui/base/button'

const props = withDefaults(defineProps<ButtonProps>(), {
  iconPosition: ui.default.iconPosition,
  linkType: ui.default.linkType,
  color: ui.default.color,
  size: ui.default.size,
})

const buttonClasses = computed(() => {
  return cn(ui.base, ui.size[props.size], ui.color[props.color], { [ui.disabled]: props.disabled })
})

const buttonComponent = computed(() => {
  return props.linkType === 'button' ? 'button' : NuxtLink
})

const iconComponent = computed(() => {
  return props.icon ? ui.icon.names[props.icon] : null
})

const iconClasses = computed(() => {
  return cn(ui.icon.base, ui.icon.position[props.iconPosition], props.color === 'outline' ? '' : ui.icon.color[props.color])
})
</script>

<template>
  <Component
    :is="buttonComponent"
    :to="to"
    :class="cn('group', buttonClasses, $attrs.class ?? '')"
    :disabled="disabled"
  >
    <span>
      <slot />
    </span>
    <Component :is="iconComponent" v-if="icon" :class="iconClasses" />
  </Component>
</template>
