import type { Profile, Residents } from '@duurzameoplossing/data_tables/profile'
import type { DeepPartial } from '~~/types/helpers'
import type { Behaviors, CreateProfile, Resident } from '~~/types/helpers/profileHelpers'
import { diff } from 'just-diff'

export const useProfileStore = defineStore('profile', () => {
  const { $api } = useNuxtApp()
  const useApiService = apiService($api)
  const { loggedIn } = storeToRefs(identityStore())

  type ProfileState = Omit<Profile, 'profile'>

  const profileState = ref<ProfileState | null>(null)
  const oldProfileRef = ref<ProfileState | null>(null)
  const profileInfo = ref<Profile['profile'] | null>(null)

  const unSafeProfile = computed(() => {
    return rfdc(profileState.value)
  })

  /** This can only be used in authenticated pages. */
  const profile = computed<ProfileState>({
    get: () => {
      if (!profileState.value)
        throw createError('useProfileStore().profile cannot be used in unauthenticated pages.')

      return rfdc(profileState.value)
    },
    set: (value) => {
      profileState.value = value
    },
  })

  /** This can only be used in authenticated pages. */
  const residence = computed<Profile['residence']>({
    get: () => {
      if (!profileState.value)
        throw createError('useProfileStore().residence cannot be used in unauthenticated pages.')

      return rfdc(profileState.value).residence
    },
    set: (value) => {
      profile.value = {
        ...profile.value,
        residence: value,
      }
    },
  })

  /** This can only be used in authenticated pages. */
  const residents = computed<Residents>({
    get: () => {
      if (!profileState.value)
        throw createError('useProfileStore().residents cannot be used in unauthenticated pages.')

      return rfdc(profileState.value).residents
    },
    set: (value) => {
      profile.value = {
        ...profile.value,
        residents: value,
      }
    },
  })

  /** This can only be used in authenticated pages. */
  const spaces = computed<Profile['spaces']>({
    get: () => {
      if (!profileState.value)
        throw createError('useProfileStore().spaces cannot be used in unauthenticated pages.')

      return rfdc(profileState.value).spaces
    },
    set: (value) => {
      profile.value = {
        ...profile.value,
        spaces: value,
      }
    },
  })

  /** This can only be used in authenticated pages. */
  const behaviors = computed<Behaviors>({
    get: () => {
      if (!profileState.value)
        throw createError('useProfileStore().behaviors cannot be used in unauthenticated pages.')

      return rfdc(profileState.value).behaviors
    },
    set: (value) => {
      profile.value = {
        ...profile.value,
        behaviors: value,
      }
    },
  })

  const createProfile = async (profile: CreateProfile) => {
    const { profile: info, ...data } = await useApiService.createProfile(profile)
    oldProfileRef.value = rfdc(data)
    profileState.value = data
    profileInfo.value = info
  }

  const getProfile = async () => {
    if (profileState.value || !loggedIn.value)
      return
    const { profile: info, ...data } = await useApiService.getProfile()

    if (data) {
      oldProfileRef.value = rfdc(data)
      profileState.value = data
      profileInfo.value = info
    }
  }

  const createResidents = async (amount: number) => {
    const { profile: info, ...data } = await useApiService.createResidents(amount)
    oldProfileRef.value = rfdc(data)
    profileState.value = data
    profileInfo.value = info
  }

  const updateResident = (residentId: string, data: Partial<Resident>) => {
    const index = residents.value.findIndex(r => r.resident_id === residentId)
    if (index === -1) {
      if (isDev)
        console.warn('[UpdateResident] Unknown residentId:', residentId)
      return
    }

    if (!profileState.value)
      throw createError('No profile to update.')

    profileState.value.residents[index] = {
      ...profileState.value.residents[index],
      ...data,
    }
  }

  const updateResidents = (data: Record<string, Partial<Resident>>) => {
    if (!profileState.value)
      throw createError('No profile to update.')

    for (const [residentId, resident] of Object.entries(data))
      updateResident(residentId, resident)
  }

  const createResident = async (data: DeepPartial<Resident>) => {
    if (!profileState.value)
      throw createError('No profile to update.')

    const { profile: _, ...newProfile } = await useApiService.patchProfile({
      ...profileState.value,
      residents: [...profileState.value.residents, data as Resident],
    })
    oldProfileRef.value = rfdc(newProfile)
    profileState.value = newProfile
  }

  const deleteResident = async (residentId: string) => {
    const { profile: info, ...data } = await useApiService.deleteResident(residentId)
    oldProfileRef.value = rfdc(data)
    profileState.value = data
    profileInfo.value = info
  }

  const getResidentById = (residentId: string) => {
    const resident = residents.value.find(r => r.resident_id === residentId)
    if (!resident)
      throw createError(`Resident not found: ${residentId}`)

    return resident
  }

  watchDebounced(
    unSafeProfile,
    () => {
      const oldValue = oldProfileRef.value
      const newValue = unSafeProfile.value
      if (!oldValue || !newValue) {
        oldProfileRef.value = rfdc(newValue)
        return
      }
      if (diff(oldValue, newValue).length === 0)
        return

      console.log('Profile updating...', diff(oldValue, newValue))
      useApiService.patchProfile(newValue) // Keeping profile up to date
      oldProfileRef.value = rfdc(newValue)
    },
    {
      maxWait: 2000,
      debounce: 1000,
      deep: true,
      immediate: true,
    },
  )

  watch(loggedIn, (value) => {
    if (!value) {
      profileState.value = null
      oldProfileRef.value = null
      profileInfo.value = null
    }
  })

  return {
    // State
    profileState,
    profileInfo,
    // Getters
    unSafeProfile,
    profile,
    residence,
    residents,
    spaces,
    behaviors,
    // Actions
    getProfile,
    createProfile,
    createResidents,
    updateResident,
    updateResidents,
    deleteResident,
    getResidentById,
    createResident,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
