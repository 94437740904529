<script setup lang="ts">
import type { TitleLevel, TitleProps } from '~~/types/base/Title'
import ui from '~/ui/base/title'

const props = withDefaults(defineProps<TitleProps>(), {
  level: ui.default.level,
  underline: ui.default.underline,
})

const titleClass = computed(() => {
  const size: TitleLevel = props.displayLevel ?? props.level
  return cn(ui.base, ui.size[size], props.underline ? ui.underline : '')
})
</script>

<template>
  <component :is="props.level" :class="cn(titleClass, $attrs.class ?? '')">
    <slot />
  </component>
</template>
