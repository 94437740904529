import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ChatBubbleLeftEllipsisIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EnvelopeIcon,
  PlusIcon,
  ShareIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/vue/24/outline'

export default {
  base: 'flex items-center gap-2 rounded-full border-2 font-sans font-semibold transition cursor-pointer w-fit',
  size: {
    xs: 'px-3 py-1 text-xs',
    sm: 'px-4 py-1.5 text-sm',
    md: 'px-6 py-2.5 text-base',
  },
  color: {
    white: 'border-gray-400 bg-white text-gray-700 hover:border-green hover:bg-green-300 hover:text-white',
    outline: 'border-green-500 bg-transparent text-green-500 hover:border-green hover:bg-green-300 hover:text-white',
    black: 'bg-black text-white',
    brand: 'border-green bg-green text-white hover:bg-green-300',
    transparent: 'border-transparent bg-transparent text-gray-700 hover:bg-gray-100 hover:text-gray-500',
  },
  disabled: 'opacity-50 cursor-not-allowed',
  icon: {
    base: 'h-5 w-5 transition',
    names: {
      'arrow-right': ArrowRightIcon,
      'arrow-left': ArrowLeftIcon,
      'arrow-up': ArrowUpIcon,
      'arrow-down': ArrowDownIcon,
      'chevron-down': ChevronDownIcon,
      'chevron-up': ChevronUpIcon,
      'chevron-right': ChevronRightIcon,
      'chevron-left': ChevronLeftIcon,
      'envelope': EnvelopeIcon,
      'user': UserIcon,
      'plus': PlusIcon,
      'trash': TrashIcon,
      'message': ChatBubbleLeftEllipsisIcon,
      'share': ShareIcon,
    },
    color: {
      white: 'text-gray-700 group-hover:text-white',
      black: 'text-white group-:text-gray-700',
      brand: 'text-white group-:text-gray-700',
      transparent: 'text-gray-700 group-:text-white',
    },
    position: {
      left: 'order-first',
      right: 'order-last',
    },
  },
  default: {
    iconPosition: 'left',
    linkType: 'internal',
    color: 'brand',
    size: 'md',
  },
} as const
