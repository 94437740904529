export default defineNuxtRouteMiddleware(async (to) => {
  if (to.query.preview === 'true') {
    const previewId = to.query.p
    const postType = to.query.post_type

    const wordpressUrl = 'https://cms.deduurzameoplossing.nl'
    const frontendSiteUrl = 'https://ikwoonbeter.nl'

    return navigateTo(
      `${wordpressUrl}/generate?redirect_uri=${frontendSiteUrl}/preview?preview_id=${previewId}&post_type=${postType}`,
      {
        external: true,
      },
    )
  }
})
