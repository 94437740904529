<script setup lang="ts">
interface Link {
  to: string
  label: string
}

const links: Link[] = [
  {
    to: '/voorwaarden/algemeen',
    label: 'Algemene voorwaarden',
  },
  {
    to: '/voorwaarden/privacy',
    label: 'Privacyverklaring',
  },
  {
    to: '/contact',
    label: 'Contact',
  },
]

const year = new Date().getFullYear()
</script>

<template>
  <footer class="mt-20 bg-neutral py-14 font-display font-normal leading-6 text-white sm:py-4">
    <div class="mx-auto flex flex-col items-center justify-between px-4 sm:flex-row">
      <div
        class="mb-10 flex flex-col items-center space-y-6 sm:order-last sm:mb-0 sm:mr-32 sm:flex-row sm:space-x-4 sm:space-y-0 sm:pr-32"
      >
        <NuxtLink v-for="link in links" :key="link.to" :data-cy="link.to" class="hover:underline" :to="link.to">
          {{ link.label }}
        </NuxtLink>
      </div>
      <div class="text-center text-xs sm:order-first sm:ml-28 sm:text-sm">
        © {{ year }} De Duurzame Oplossing BV - Alle rechten voorbehouden
      </div>
    </div>
  </footer>
</template>
