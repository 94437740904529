import type { PersonalHygieneResult } from '~~/types/api/compute/personalHygiene'
import { ApiService } from '@/services/ApiService'

export class ComputeService extends ApiService {
  getPersonalHygieneResult(profileDeviations: any = {}, refresh: boolean = false) {
    return this.axios
      .post<PersonalHygieneResult>('/compute/personal_hygiene', profileDeviations, { params: { refresh } })
      .then((response) => {
        return response.data.result
      })
  }
}

export default new ComputeService()
