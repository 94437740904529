import type { Onboarding } from '~~/types/api/OnBoarding'

/**
 * Post should return updated data... not a message that it was successful
 */

const enforceDefaults = (data: Partial<Onboarding>) => {
  data.completed ??= false
  data.wizard_bathroom ??= false
  data.wizard_kitchen ??= false
}

export const useOnboarding = async () => {
  const { $api } = useNuxtApp()
  const useApiService = apiService($api)
  const { profileInfo } = storeToRefs(useProfileStore())

  const cache = useState<Onboarding | null>(`onboarding-data-${profileInfo.value?.user_id ?? 'guest'}`, () => null)

  const onboardingData = computed(() => {
    const data = unref(cache)
    if (!data) {
      const defaults = {} as Onboarding
      enforceDefaults(defaults)
      return defaults
    }
    else {
      return data
    }
  })

  const getData = async () => {
    try {
      const data = await useApiService.getOnboarding()
      data.completed ??= false
      cache.value = data
    }
    catch (error) {
      if (isDev)
        console.error('Error fetching onboarding data', error)
    }
  }

  if (!cache.value)
    await getData()

  const saveOnboardingData = async (data: Partial<Onboarding>) => {
    try {
      const updatedData = { ...onboardingData.value, ...data }
      await useApiService.saveOnboarding(updatedData)
      await getData()
    }
    catch (error) {
      if (isDev)
        console.error('Error saving onboarding data', error)
    }
  }

  const isKeyComplete = (key: keyof Onboarding) => {
    return onboardingData.value[key]
  }

  return {
    onboardingData,
    saveOnboardingData,
    isKeyComplete,
  }
}
