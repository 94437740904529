export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local')
    console.log('01.testEnvBlockade.global.ts')

  const env = import.meta.env.VITE_ENVIRONMENT
  const nuxtApp = useNuxtApp()
  let host = ''
  if (process.server) {
    // for 3.0.0.rc_vercions: host = nuxtApp.ssrContext.req.headers.host
    // UPD 27.01.23:
    host = nuxtApp.ssrContext?.event.node.req.headers.host ?? ''
  }
  else {
    host = window.location.host
  }
  if (
    env !== 'prod'
    && !host.startsWith('localhost')
    && to.path !== '/login-for-test-site'
    && useCookie('test-site-password').value !== 'ddop_test_site'
  ) {
    return navigateTo('https://ikwoonbeter.nl', { external: true })
  }
})
