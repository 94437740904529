export default defineNuxtRouteMiddleware(async (to) => {
  const routeList = ['/plannen/*', '/calculators/*', '/mijn-woning/*']
  const measuresStore = useMeasureStore()

  if (routeList.some(s => to.path.match(s))) {
    try {
      await measuresStore.fetchMeasures()
    }
    catch (_err) {}
  }
})
