import { captureException } from '@sentry/vue'

export default defineNuxtRouteMiddleware(async () => {
  const profileStore = useProfileStore()

  try {
    await profileStore.getProfile()
  }
  catch (error) {
    if (isDev) {
      console.error('Error fetching profile', error)
      captureException({
        message: 'Error fetching profile',
        error,
      })
    }
  }
})
