// @ts-expect-error Could not find a declaration file for module 'vue-matomo'
import VueMatomo from 'vue-matomo'
import { useRouter } from 'vue-router'

const testBuild = import.meta.env.VITE_TEST_BUILD === 'true'

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local')
    return

  const router = useRouter()
  nuxtApp.vueApp.use(VueMatomo, {
    host: 'https://ddop.matomo.cloud/',
    siteId: testBuild ? 2 : 1,
    router,
    trackInitialView: false, // anders wordt de call twee keer gelogd
  })
})
