import type { ComputeResults } from '~~/types/api/Compute'
import type { CreateMeasure, Measure } from '~~/types/api/Measures'
import type { Onboarding } from '~~/types/api/OnBoarding'
import type { Preferences, preferencesData } from '~~/types/api/Preferences'
import type { Profile } from '~~/types/api/ProfileNew'
import type { CreateProfile } from '~~/types/helpers/profileHelpers'
import type { $Fetch, NitroFetchRequest } from 'nitropack'

export const apiService = <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
  // Profile
  async getProfile() {
    return fetch<Profile>('/data/profile')
  },
  async createProfile(profile: CreateProfile) {
    return fetch<Profile>('/data/profile', {
      method: 'POST',
      body: profile,
    })
  },
  async createResidents(amount: number) {
    return fetch<Profile>(`/data/residents/${amount}`, {
      method: 'POST',
      body: {},
    })
  },
  async patchProfile(profile: Partial<Profile>) {
    return fetch<Profile>('/data/profile', {
      method: 'PATCH',
      body: profile,
    })
  },
  async deleteResident(residentId: string) {
    return fetch<Profile>(`/data/residents/${residentId}`, {
      method: 'DELETE',
    })
  },

  // Measures
  async getMeasures() {
    return fetch<Measure[]>('/data/measures')
  },
  async saveMeasure(measureData: CreateMeasure) {
    return fetch<Measure>('/data/measures', {
      method: 'POST',
      body: measureData,
    })
  },
  async implementMeasure(measureId: string) {
    return fetch<Measure>(`/data/measures/${measureId}/implement`, {
      method: 'POST',
      body: { measure_id: measureId },
    })
  },
  async deleteMeasure(measureId: string) {
    return fetch(`/data/measures/${measureId}`, {
      method: 'DELETE',
    })
  },

  // Compute
  async getComputeResults<ComputeResultKey extends keyof ComputeResults & string>(
    computeKey: ComputeResultKey,
    body: Omit<Profile, 'profile'>,
    refresh = false,
  ) {
    return fetch<ComputeResults[ComputeResultKey]>(`/compute/${computeKey}` as string, {
      method: 'POST',
      body,
      query: { refresh },
    })
  },

  // Onboarding
  async getOnboarding() {
    return fetch<Onboarding>('/data/onboarding')
  },
  async saveOnboarding(onboardingData: { completed: boolean }) {
    return fetch<Onboarding>('/data/onboarding', {
      method: 'POST',
      body: onboardingData,
    })
  },

  // Preferences
  async getPreferences() {
    return fetch<Preferences>('/data/preferences')
  },
  async addOrDeletePreference(body: preferencesData) {
    return fetch<Preferences>('/data/preferences', {
      method: 'POST',
      body,
    })
  },
})
