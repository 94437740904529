<script setup lang="ts">
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { identityStore } from '~/stores/identityStore'

const identity = identityStore()

const route = useRoute()

const mobileMenuOpen = ref(false)

const menu = [

  {
    label: 'Artikelen',
    to: '/artikelen',
  },
  {
    label: 'Mijn woning',
    to: '/mijn-woning',
  },
]

const showAuthLinks = computed(() => {
  const removeAuthLinksOnThesePages = ['/login', '/signup', '/forgot-password', '/verify-email']
  return !removeAuthLinksOnThesePages.includes(route.path)
})
</script>

<template>
  <header class="mb-4 bg-white/80 py-4">
    <div class="mx-auto flex w-full max-w-7xl items-center justify-between px-4">
      <NuxtLink to="/" class="-translate-y-2 md:-translate-y-4">
        <BaseIcon name="ikwoonbeter_logo" class="logo [&>svg]:max-w-[150px] md:[&>svg]:max-w-[230px]" />
      </NuxtLink>
      <div class="flex items-center gap-6">
        <div v-if="identity.loggedIn" class="header-filler hidden justify-center gap-6 md:flex">
          <NuxtLink
            v-for="item in menu"
            :key="item.to"
            :to="item.to"
            class="font-sans font-bold hover:underline"
            :class="{
              'text-green': route.path.includes(item.to),
              'text-neutral': !route.path.includes(item.to),
            }"
            prefetch
          >
            {{ item.label }}
          </NuxtLink>
        </div>
        <div v-if="showAuthLinks" class="flex gap-2">
          <BaseButton v-if="!identity.loggedIn" to="/login" icon="user" icon-position="left">
            Inloggen
          </BaseButton>

          <NavigationProfileMenu v-if="identity.loggedIn" />
          <div class="flex md:hidden">
            <button
              type="button"
              class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              @click="mobileMenuOpen = true"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon class="size-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <Dialog as="div" class="md:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <NuxtLink to="/" class="-translate-y-2 md:-translate-y-4">
            <BaseIcon name="ikwoonbeter_logo" class="logo [&>svg]:max-w-[150px] md:[&>svg]:max-w-[230px]" />
          </NuxtLink>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="size-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="flex flex-col space-y-6 pb-10 pt-8">
              <NuxtLink
                v-for="item in menu"
                :key="item.to"
                :to="item.to"
                class="text-xl font-semibold hover:underline"
                :class="{
                  'text-green': route.path.includes(item.to),
                  'text-neutral': !route.path.includes(item.to),
                }"
                @click="mobileMenuOpen = false"
              >
                {{ item.label }}
              </NuxtLink>
            </div>

            <div v-if="!identity.loggedIn" class="flex w-full flex-col gap-4 py-6">
              <BaseButton to="/login" color="white" class="w-full justify-center">
                Inloggen
              </BaseButton>
              <BaseButton to="/signup" color="brand" class="w-full justify-center">
                Aanmelden
              </BaseButton>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<style lang="postcss">
.logo {
  display: block;
  /* svg {
    width: 127px;
    height: 56px;
  } */
}
</style>
