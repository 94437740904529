import type { FormKitNode, FormKitPlugin } from '@formkit/core'

export const minMaxPlugin: FormKitPlugin = (node: FormKitNode) => {
  const nodeRef = ref<FormKitNode | null>(null)
  if (node.type !== 'input')
    return

  const rules: any[] = node.props.parsedRules
  const minimum = rules.find(r => r.name === 'min')?.args?.[0]
  const maximum = rules.find(r => r.name === 'max')?.args?.[0]

  if (!minimum && !maximum)
    return

  node.props.attrs.onBlur = (e: any) => {
    if (!nodeRef.value)
      return
    nodeRef.value.props.focussed = false
    const value = Number.parseInt(e.target.value)
    if (!value) {
      if (minimum)
        return node.input(minimum)
      return node.input('')
    }
    if (minimum && value < minimum)
      return node.input(minimum)

    if (maximum && value > maximum)
      return node.input(maximum)

    node.input(value)
  }

  node.props.attrs.onFocus = () => {
    if (!nodeRef.value)
      return

    nodeRef.value.props.focussed = true
  }

  nodeRef.value = node

  node.hook.input((payload, next) => {
    if (node.props.focussed)
      return next(node.value)
    else
      return next(payload)
  })
}
