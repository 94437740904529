import { nl } from '@formkit/i18n'
import { createProPlugin, inputs } from '@formkit/pro'
import { defineFormKitConfig } from '@formkit/vue'

import { minMaxPlugin } from './app/formkitPlugins/minMax.js'
import { rootClasses } from './formkit.theme.js'

export default defineFormKitConfig(() => {
  const config = useRuntimeConfig()

  const pro = createProPlugin(config.public.FORMKIT_PRO_KEY, inputs)

  return {
    plugins: [pro, minMaxPlugin],
    locales: { nl },
    locale: 'nl',
    config: {
      rootClasses,
    },
    iconLoader: async (iconName: string) => {
      // using heroicons fonts in formkit elements
      try {
        if (!iconName)
          return undefined

        if (iconName === 'radioDecorator')
          return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><circle fill="currentColor" cx="16" cy="16" r="16"></circle></svg>'

        const response = await fetch(`https://cdn.jsdelivr.net/npm/heroicons/24/outline/${iconName}.svg`)
        const icon = await response.text()
        return icon.startsWith('<svg') ? icon : undefined
      }
      catch (error) {
        console.error(error)
        return undefined
      }
    },
  }
})
