import type { ResidenceDeviation, Resident } from '~~/types/api/Profile'
import type { Profile } from '~~/types/api/ProfileNew'
import type { ProfileDeviation } from '~~/types/helpers/profileHelpers'

export const isSameDeviation = (deviationA: Record<string, any>, deviationB: Record<string, any>) => {
  const devA = rfdc(deviationA)
  const devB = rfdc(deviationB)

  const isSame = (A: any, B: any): boolean => {
    for (const key in A) {
      if (Object.hasOwnProperty.call(A, key) !== Object.hasOwnProperty.call(B, key))
        return false
      const valueA = A[key]
      const valueB = B[key]

      if (typeof valueA !== typeof valueB)
        return false
      if (!!valueA !== !!valueB)
        return false

      if (typeof valueA !== 'object' || valueA === null) {
        if (valueA !== valueB)
          return false
      }
      else if (Array.isArray(valueA) && Array.isArray(valueB)) {
        if (valueA.length !== valueB.length)
          return false
        for (const arrayKey in valueA) {
          const arrayValueA = valueA[arrayKey]
          const arrayIndexB = valueB.findIndex(v => isSame(v, arrayValueA))
          if (arrayIndexB === -1)
            return false
          valueB.splice(arrayIndexB, 1)
        }
      }
      else {
        return isSame(valueA, valueB)
      }
    }

    return true
  }

  return isSame(devA, devB)
}

export const combineDeviations = (...deviationObj: Record<string, any>[]) => {
  return rfdc(deviationObj).reduce<any>((acc, deviation) => {
    if (!acc.residence_id)
      return deviation

    if ('residents' in deviation) {
      for (const residentDeviation of deviation.residents) {
        acc.residents ??= []
        const index = (acc.residents as any[]).findIndex(r => r.resident_id === residentDeviation.resident_id)
        if (index === -1) {
          acc.residents.push(residentDeviation)
        }
        else {
          acc.residents[index] = {
            ...acc.residents[index],
            ...residentDeviation,
          }
        }
      }
    }
    else {
      acc = {
        ...acc,
        ...deviation,
      }
    }

    return acc
  }, {})
}

export const isResidentInDeviation = (deviationObject: ResidenceDeviation, residentId: string) => {
  if (!('residents' in deviationObject))
    return false
  if (!Array.isArray(deviationObject.residents) || deviationObject.residents.length <= 0)
    return false
  return !!deviationObject.residents.find(resident => resident?.resident_id === residentId)
}

export const mergeDeviationObject = (profile: Omit<Profile, 'profile'>, deviation: ProfileDeviation) => {
  const merge = (value: Record<string, any>, deviationValue: Record<string, any>) => {
    for (const key of Object.keys(deviationValue)) {
      const profileTypeKey = typeof value[key]
      const deviationTypeKey = typeof deviationValue[key]
      if (profileTypeKey === deviationTypeKey && profileTypeKey === 'object') {
        if (Array.isArray(value[key]) && Array.isArray(deviationValue[key])) {
          if (key === 'residents') {
            for (const residentDeviation of deviationValue[key]) {
              const resident = (value[key] as Resident[]).find(r => r.resident_id === residentDeviation.resident_id)
              if (resident)
                Object.assign(resident, residentDeviation)
            }
          }
        }
        else {
          merge(value[key] as Record<string, unknown>, deviationValue[key] as Record<string, unknown>)
        }
      }
      else {
        value[key] = deviationValue[key]
      }
    }
  }

  return merge(profile, deviation)
}
