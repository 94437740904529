import axios from 'axios'
import { identityStore } from '~/stores/identityStore'

const defaultBaseURL: string = `${import.meta.env.VITE_BACKEND_URL}/api`

export class ApiService {
  private _baseURL: string = defaultBaseURL

  constructor(baseURL: string = defaultBaseURL) {
    if (baseURL)
      this._baseURL = baseURL
  }

  public get axios() {
    const pinia = usePinia()
    const store = identityStore(pinia)
    const token = store.token

    const commonHeaders: { [headerName: string]: string } = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }

    console.log('_baseURL', this._baseURL)

    if (process.server)
      commonHeaders.Referer = 'https://github.com/duurzameoplossing/'

    return axios.create({
      baseURL: this._baseURL,
      headers: {
        post: {
          'Content-Type': 'application/json',
        },
        put: {
          'Content-Type': 'application/json',
        },
        patch: {
          'Content-Type': 'application/json',
        },
        common: commonHeaders,
      },
    })
  }
}
