<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    extraClasses?: string
    filled?: boolean
    path?: string
  }>(),
  { filled: false, path: 'icons' },
)

const icon = ref<string | Record<string, any>>('')

const getIcon = async () => {
  try {
    const iconsImport = import.meta.glob(`assets/**/**/**.svg`, {
      query: '?raw',
      import: 'default',
      eager: false,
    })
    const rawIcon = await iconsImport[`/assets/${props.path}/${props.name}.svg`]()

    icon.value = rawIcon
  }
  catch {
    console.error(`[nuxt-icons] Icon '${props.name}' doesn't exist in 'assets/${props.path}'`)
  }
}

await getIcon()

watchEffect(getIcon)
</script>

<template>
  <div class="nuxt-icon" :class="extraClasses" v-html="icon" />
</template>

<style lang="postcss">
.nuxt-icon.light path {
  color: var(--primary-text-color);
  stroke: var(--primary-text-color);
}
</style>
