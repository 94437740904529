<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    background?: boolean
    filled?: boolean
  }>(),
  {
    filled: false,
    background: false,
  },
)

const icon = ref<string | Record<string, any>>('')

const getIcon = async () => {
  try {
    const iconsImport = import.meta.glob('assets/doppie/**/**.svg', {
      query: '?raw',
      import: 'default',
      eager: false,
    })
    const rawIcon = await iconsImport[`/assets/doppie/Doppie=${props.name}.svg`]()

    icon.value = rawIcon
  }
  catch {
    console.error(`Doppie '${props.name}' doesn't exist in 'assets/doppie'`)
  }
}

await getIcon()

watchEffect(getIcon)
</script>

<template>
  <span class="doppie-image" :class="{ doppie_background: background }" v-html="icon" />
</template>

<style>
.doppie-image svg {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}
.doppie_background {
  background-color: var(--secundair-inactive-color);
}
</style>
