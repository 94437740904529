<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { UserCircleIcon } from '@heroicons/vue/24/outline'
import { identityStore } from '~/stores/identityStore'

const identity = identityStore()

const onLogout = async () => {
  await identity.logOut()
}

type NavLink = {
  label: string
  to: string
}

const links: NavLink[] = [
  {
    label: 'Mijn omgeving',
    to: '/',
  },
  {
    label: 'Account',
    to: '/account',
  },
  {
    label: 'Wachtwoord wijzigen',
    to: '/account/change-password',
  },
]

const { $posthog } = useNuxtApp()
const trackOpenProfilemenu = () => {
  $posthog.capture('open_profile_menu')
}
</script>

<template>
  <Menu as="div" data-cy="user-menu-button" class="relative flex text-left">
    <MenuButton class="inline-flex w-full justify-center rounded-full bg-gray-50 hover:bg-green-100" @click="trackOpenProfilemenu()">
      <UserCircleIcon class="size-8 text-gray-500" aria-hidden="true" />
    </MenuButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-8 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="px-4 py-3">
          <p class="text-sm">
            Ingelogd met
          </p>
          <p class="truncate text-sm font-medium text-gray-900" data-matomo-mask>
            {{ identity.email }}
          </p>
        </div>
        <div class="py-1">
          <MenuItem v-for="link in links" :key="link.to" v-slot="{ active, close }" as="div">
            <NuxtLink
              :key="link.to"
              :to="link.to"
              class="block px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
              @click="close"
            >
              {{ link.label }}
            </NuxtLink>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <button
              data-cy="user-logout"
              class="block w-full px-4 py-2 text-left text-sm" :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              ]"
              @click="onLogout"
            >
              Uitloggen
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
