import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (import.meta.env.VITE_ENVIRONMENT === 'local')
    return

  if (!sentry.dsn) {
    console.warn('Sentry DSN not set, skipping Sentry init')
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.env,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),

    ],
    tunnel: '/api/sentry/tunnel',

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://ikwoonbeter.nl', /https:\/\/deploy-preview-\d+\.ikwoonbeter\.nl/],

    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
