export default {
  base: 'font-black font-sans text-neutral',
  size: {
    // old:
    // h1: 'text-3xl sm:text-4xl',
    // h2: 'text-3xl',
    // h3: 'text-2xl',
    // h4: 'text-xl',
    // h5: 'text-base',
    // h6: 'text-sm'
    h1: 'text-h1',
    h2: 'text-h2',
    h3: 'text-h3',
    h4: 'text-h4',
    h5: 'text-h5',
  },
  underline: 'after:block after:h-[5px] after:bg-green after:w-[127px] after:content-[""] pb-3 after:mt-1.5',
  default: {
    level: 'h2',
    underline: false,
  },
} as const
