import type { Measure } from '~~/types/api/Measures'

export const useMeasureStore = defineStore('measures', () => {
  const { $api } = useNuxtApp()
  const useApiService = apiService($api)
  const profileStore = useProfileStore()

  type MeasuresObj = { [key: string]: Measure }
  const measuresState = ref<MeasuresObj | null>(null)

  const allMeasures = computed(() => {
    if (measuresState.value === null)
      throw createError('Measures not loaded')

    return measuresState.value
  })

  const measures = computed(() => {
    return Object.fromEntries(Object.entries(allMeasures.value).filter(([_, measure]) => !measure.implemented))
  })

  const implementedMeasures = computed(() => {
    Object.fromEntries(Object.entries(allMeasures.value).filter(([_, measure]) => measure.implemented))
  })

  const getMeasure = (id: string) => {
    return allMeasures.value[id]
  }

  const getMeasures = (filter?: (_measure: Measure) => boolean) => {
    if (typeof filter === 'function')
      return Object.values(measures.value).filter(filter)

    return Object.values(measures.value)
  }

  const deleteMeasure = async (id: string) => {
    await useApiService.deleteMeasure(id)
    delete allMeasures.value[id]
  }

  const deleteAll = async () => {
    const ids = Object.keys(allMeasures.value) as string[]
    for (const id of ids)
      await deleteMeasure(id)
  }

  const createMeasure = async (type: string, deviationObj: any) => {
    const foundMeasure = Object.values(measures.value).find(
      measure => measure.measure_type === type && isSameDeviation(measure.deviation_object, deviationObj),
    )
    if (foundMeasure)
      return foundMeasure

    const measure = await useApiService.saveMeasure({
      measure_type: type,
      deviation_object: deviationObj,
      savings: {},
      user_inputs: {},
      profile_version: new Date().toISOString(),
    })
    allMeasures.value[measure.measure_id] = measure
    return measure
  }

  const implementMeasure = async (measureId: string) => {
    const result = await useApiService.implementMeasure(measureId)
    allMeasures.value[measureId] = result
    await profileStore.getProfile() // revalidate profile
    return result
  }

  /** @deprecated */
  const getHygieneResultsFromMeasure = async (measureId: string) => {
    const personalHygieneStore = usePersonalHygieneStore()
    const measure = allMeasures.value[measureId]
    return await personalHygieneStore.fetchWithDeviation(measure.deviation_object)
  }

  /** @deprecated */
  const getHygieneResultsDiff = async (measureId: string) => {
    const personalHygieneStore = usePersonalHygieneStore()
    const measureHygieneResult = await getHygieneResultsFromMeasure(measureId)
    return personalHygieneStore.compare(measureHygieneResult)
  }

  /** @deprecated */
  const getHygieneResultsFromMeasures = async (...measureIds: string[]) => {
    const personalHygieneStore = usePersonalHygieneStore()
    if (measureIds.length <= 0)
      return // empty compare (returns 0 for everything)
    const combinedDeviationObj = combineDeviations(
      ...Object.values(allMeasures.value)
        .filter(measure => measureIds.includes(measure.measure_id))
        .map(m => m.deviation_object),
    )

    const hygieneResult = await personalHygieneStore.fetchWithDeviation(combinedDeviationObj)
    return hygieneResult
  }

  /** @deprecated */
  const getHygieneResultsDiffFromMeasures = async (...measureIds: string[]) => {
    const personalHygieneStore = usePersonalHygieneStore()
    const result = await getHygieneResultsFromMeasures(...measureIds)
    return personalHygieneStore.compare(result)
  }

  const fetchMeasures = async () => {
    return useApiService.getMeasures().then((measures) => {
      measuresState.value = measures.reduce<MeasuresObj>((acc, measure) => {
        acc[measure.measure_id] = measure
        return acc
      }, {})
    })
  }

  return {
    // State
    measuresState,

    // Getters
    measures,
    implementedMeasures,

    // Actions
    createMeasure,
    deleteMeasure,
    deleteAll,
    getMeasure,
    getMeasures,
    implementMeasure,
    getHygieneResultsDiffFromMeasures,
    getHygieneResultsFromMeasures,
    getHygieneResultsDiff,
    fetchMeasures,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMeasureStore, import.meta.hot))
