import posthog from 'posthog-js'

export default defineNuxtPlugin(() => {
  const { loggedIn, email, displayFullName } = storeToRefs(identityStore())
  const runtimeConfig = useRuntimeConfig()
  const posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
    api_host: '/ingest',
    ui_host: 'https://eu.posthog.com',
    capture_pageview: false,
    loaded: (posthog) => {
      if (import.meta.env.MODE === 'development')
        posthog.debug()
    },
  })

  // Make sure that pageviews are captured with each route change
  const router = useRouter()
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath,
      })
    })
  })

  if (typeof posthogClient !== 'object') {
    throw createError('PostHog could not be initialized')
  }

  const identifyUser = () => {
    if (loggedIn.value) {
      posthog.identify(email.value, {
        name: displayFullName.value,
      })
    }
    else {
      posthog.reset()
    }
  }

  onMounted(() => {
    identifyUser()
  })

  watch([loggedIn, email, displayFullName], () => {
    identifyUser()
  }, { immediate: true })

  return {
    provide: {
      posthog,
    },
  }
})
