const routeList = ['/onboarding/*']

export default defineNuxtRouteMiddleware(async (to) => {
  const { loggedIn } = storeToRefs(identityStore())
  if (loggedIn.value) {
    const { onboardingData } = await useOnboarding()
    const routeListMatch = routeList.some(s => to.path.match(s))

    if (!onboardingData.value.completed && !routeListMatch)
      return navigateTo('/onboarding')

    if (onboardingData.value.completed && routeListMatch) {
      return navigateTo('/')
    }
  }
})
